<!--@author Lucas Mello <lucas.mello@icaromediagroup.com>  -->
<!-- Component that display the user clips  -->

<template>
  <div>
    <div class="title-clips-container">
      <video-icon />
      <span>Game Clips</span>
      <span
        @click="$store.dispatch('GET_ALL_ID_CLIPS', { id: $route.params.id })"
        ><refresh-icon
      /></span>
      <span v-if="currentPlaying" class="playing">
        Playing clip: {{ currentPlaying }}
      </span>
    </div>
    <table class="my-table">
      <thead>
        <tr>
          <th>Start Time</th>
          <th>Clip Name</th>
          <th>Clip Duration</th>
        </tr>
      </thead>
      <tbody v-if="normalArray">
        <tr v-for="item in normalArray" :key="item.id">
          <td>{{ item.start_time }}</td>
          <td>{{ item.out_video_name }}</td>
          <td class="flex-container">
            <span>{{ item.clip_duration }}</span>   
            <div class="flex-auto">    
              <span v-if="!isLoading" @click="downloadClip(item)">
                <downloadIcon class="margin-icons" />
              </span>
              <span v-if="isLoading">
                <hourglassIcon class="margin-icons" />
              </span>
              <span @click="deleteClip(item.id)">
                <delete-icon class="margin-icons" />
              </span>
              <span @click="playClip(item.start_time, item.clip_duration, item.out_video_name)">
                <play-icon class="margin-icons" />
              </span>
            </div> 
          </td>          
        </tr>
      </tbody>
    </table>   
    <Popup v-if="isLoading" :name="downloadName" />
  </div>
</template>

<script>
import { EventBus } from '../../utils/event-bus';
import deleteIcon from '@/assets/img/delete';
import downloadIcon from '@/assets/img/download';

import hourglassIcon from '@/assets/img/hourglass';
import videoIcon from '@/assets/img/video';
import refreshIcon from '@/assets/img/refresh';
import playIcon from '@/assets/img/play';
import Popup from '@/components/clip/ClipDownloadPopup.vue';

export default {
  name: 'clipsdisplayed',
  components: { deleteIcon, downloadIcon, videoIcon, refreshIcon, playIcon, Popup, hourglassIcon },
  data() {
    return {
      isLoading: false,
      downloadName: '',
      currentPlaying: false,
      normalArray: []
    };
  },
  props: {
    myArray: {
      type: Array,
      required: false,
    },
  },
  watch: {
    myArray: {
      immediate: true,
      handler(newArray) {
        this.normalArray = [...newArray];
      },
    },
  }, 
  methods: {
    async downloadClip(item) {
    try {
      this.isLoading = true;
      this.downloadName = item.out_video_name;
      let url = await this.$store.dispatch('GET_DOWNLOAD_CLIP', { id: item.id });
      const retryIntervals = [5000, 10000, 15000, 15000, 15000, 15000]; 
      const maxTries = retryIntervals.length;
      let retryCount = 0;
      while ((!url || url.data === '') && retryCount < maxTries) {
        const retryInterval = retryIntervals[retryCount];
        await new Promise(resolve => setTimeout(resolve, retryInterval));
        url = await this.$store.dispatch('GET_DOWNLOAD_CLIP', { id: item.id });
        retryCount++;
      }
      if (!url || url.data === '') {
        throw new Error('Maximum retry limit reached. Failed to obtain valid URL.');
      }
      const link = document.createElement('a');
      link.href = url.data;
      link.setAttribute('download', 'file.mp4');
      link.click();
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } catch (error) {
      console.error('Error occurred while downloading clip:', error);
      this.isLoading = false;
    }
},
    playClip(start_time, clip_duration, currentPlaying) {
      this.currentPlaying = currentPlaying;
      const startSeconds = this.timeToSeconds(start_time);
      const clipDuration = this.timeToSeconds(clip_duration);
      EventBus.$emit('play', { startSeconds, clipDuration });
    },
    deleteClip(id) {
      this.$store.dispatch('GET_DELETE_CLIP', { id: id });
      const index = this.normalArray.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.normalArray.splice(index, 1);
        this.normalArray = [...this.normalArray];
      }
    },
    timeToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-container span{
  margin: auto;
}

.flex-auto{
  display: flex;
}
.flex-auto span{
  margin: auto;
}

.margin-icons {
  margin-left: 0px !important;
  cursor: pointer;
}

.my-table {
  width: 90vw;
}
}
.title-clips-container {
  padding: 8px;
  display: flex;
}

.title-clips-container span {
  margin-left: 8px;
  font-size: 18px;
}

.title-clips-container .playing {
  color: red;
  font-size: 14px;
  margin-left: auto;
}

.margin-icons {
  margin-left: 20px;
  cursor: pointer;
}
.my-table {
  border-collapse: collapse;  
}

.my-table th,
.my-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.my-table th {
  background-color: #f2f2f2;
}

.my-table tbody tr:hover {
  background-color: #f5f5f5;
}
</style>
