<template>
    <div id="popup-container">
      <div id="popup-content">
        <div class="loading-text">Preparing your video for download</div>
        <div class="name-text">{{ name }}.mp4</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Popup',
    props: {
      name: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  #popup-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  #popup-content {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    border-bottom: 5px solid #003876;
  }
  
  .loading-text {
    font-weight: bold;
  }
  
  .name-text {
    font-style: italic;
    font-size: 14px;
    margin-top: 5px;
    font-weight: normal;
  }
  </style>